<template>
    <CCard class="p-0">
        <CCardBody>
            <CListGroup class="mb-10">
                <CListGroupItem href="#" v-for="(item, index) in notifications" :key="index" @click="read(item)" class="flex-column align-items-start">
                    <div class="d-flex w-100">
                        <div class="flex-grow-0 mr-10">
                            <div class="media-img-wrap">
                                <img :src="avatar" alt="user" class="avatar-img rounded-circle img-fluid">
                            </div>
                        </div>
                        <div class="flex-grow-1">
                            <h5>
                                {{ item.no_ticket }}
                            </h5>
                            <div class="d-flex w-100">
                                <b class="mb-1 flex-grow-1">
                                    {{ item.sender_user.fullname }}
                                </b>
                                <span class="flex-grow-0">
                                    <vue-time-ago class="vue-fontawesome time-ago-notif" refresh :datetime="(item.created_at)" locale="id" :long="true" />
                                    <span v-if="!item.is_cleared" class="badge-wrap ml-10 text-info small">
                                        <font-awesome-icon icon="circle"/>
                                    </span>
                                </span>
                            </div>
                            <small>{{item.message}}</small>
                        </div>
                    </div>
                </CListGroupItem>
            </CListGroup>
            <CButton v-if="showMore" @click="showMoreList" class="btn-link btn-sm w-100">
                <font-awesome-icon v-if="loader" icon="spinner" spin class="mr-10"/>
                <font-awesome-icon v-else icon="chevron-down" class="mr-10"/>
                Lihat lebih banyak
            </CButton>
            <CButton v-if="!showMore" @click="resetList" class="btn-link btn-sm w-100">
                <font-awesome-icon icon="chevron-up" class="mr-10"/>
                Muat lebih sedikit
            </CButton>
        </CCardBody>
    </CCard>
</template>

<script>
const avatar = require('@/assets/img/avatar.svg')
export default {
    data () {
        return {
            avatar,
            loader: false,
			notifications: [],
			showMore: true,
			showMoreIndex: 0,

			print: ''
        }
    },
    mounted () {
        this.getNotificationList()
    },
    methods: {
        getNotificationList() {
			this.loader = true
			this.$store.dispatch({
                type: 'notification/loadNotifTicket',
				pagination: {
					limit: 15,
					offset: this.showMoreIndex
				}
			}).then( res => {
				if (res.result.length > 0) {
					const data = res.result
					for (let i = 0; i < data.length; i++) {
                        const notifProp = data[i];
                        this.notifications.push(notifProp)
					}
					this.showMoreIndex = this.notifications.length
				} else {
					this.showMore = false
				}
			}).catch( err => {
				console.log('Failed to fetch notificaiton data')
				console.log(err)
			}).finally( () => {
				this.loader = false
			})
		},
		showMoreList () {
			this.getNotificationList()
		},
		resetList () {
			this.notifications = []
			this.showMore = true
			this.showMoreIndex = 0
			this.getNotificationList()
		},
		read(item){
            this.$store.dispatch({
				type: 'notification/readNotifTicket',
				formInput: {
					id: item.id,
					isClear: true,
				}
			}).then( res => {
				// this.getNotificationList()
			}).finally(() => {
				// ticket route path
				this.$router.push('/help/ticket')
			})
		}
    }
}
</script>
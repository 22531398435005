<template>
<div class="notification-container">
	<div class="body-container">
		<CTabs :active-tab.sync="activeTab">
			<!-- New Notification -->
			<CTab>
				<template slot="title">Pemberitahuan Terbaru</template>
				<notification-group :notification-type-prop="`new`"/>
			</CTab>
			<!-- Read Notification -->
			<CTab>
				<template slot="title">Pemberitahuan Lama</template>
				<notification-group :notification-type-prop="`old`"/>
			</CTab>
			<!-- Read Notification -->
			<CTab>
				<template slot="title">Pemberitahuan Tiket</template>
				<notification-ticket/>
			</CTab>
		</CTabs>
	</div>
</div>
</template>

<script>
import {
	createNamespacedHelpers,
    mapState,
    mapGetters
} from 'vuex'

import NotificationGroup from '@/components/notification/NotificationGroup.vue'
import NotificationTicket from '@/components/notification/NotificationTicket.vue'
export default {
	components: {
		'notification-group': NotificationGroup,
		'notification-ticket': NotificationTicket
	},
    names: 'Notification',
	data() {
        return {
			activeTab: 0
        }
	}
}
</script>

<style scoped lang="scss">
span.vue-fontawesome > span.v-time-ago__text{
	font-size: 80%!important;
	margin-right: 10px;
}	
</style>